import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import i1 from "../assets/images/soulection-in-motion.png";
import i2 from "../assets/images/bluetopaztoken.png";
import i3 from "../assets/images/bluetoken.png";
import i4 from "../assets/images/greentoken.png";
// import i3 from "../assets/images/discord.svg";
// import i4 from "../assets/images/instagram.svg";
// import i5 from "../assets/images/twitter.svg";
// import Slider from "react-slick";
import { IoChevronForwardSharp, IoChevronBackSharp } from "react-icons/io5";
// import { FaDiscord, FaTwitter } from "react-icons/fa";
// import { GrInstagram } from "react-icons/gr";
// import { gsap, Power2 } from "gsap/all";
// import Canvas from "../pages/canvas";
import {AnimatedSocialIcon} from 'react-animated-social-icons';
// import "../pages/Button.js";
import Slider from "./Example";
// import backgroundH from "https://media4.giphy.com/media/QexsmmtdYNOkPhxHlj/giphy.gif?cid=ecf05e47m7jtcp4t8h9t45lv7y0ejqysrvx4jrcejwf4e1ag&rid=giphy.gif&ct=g";
// import image from "../assets/images/giphy.gif";
import Countdown from './Countdown';




// import Image from "next/image";

// const images = [astronaut, celebrating, education, taken];

const slideData = [
  {
    index: 0,
    // headline: 'New Fashion Apparel',
    // button: 'Shop now',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0001.png'

  },
  {
    index: 1,
    // headline: 'In The Wilderness',
    // button: 'Book travel',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0002.png'
  },
  {
    index: 2,
    // headline: 'For Your Current Mood',
    // button: 'Listen',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0003.png'
  },
  {
    index: 3,
    // headline: 'New Fashion Apparel',
    // button: 'Shop now',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0004.png'

  },
  {
    index: 4,
    // headline: 'In The Wilderness',
    // button: 'Book travel',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0005.png'
  },
  {
    index: 5,
    // headline: 'For Your Current Mood',
    // button: 'Listen',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0006.png'
  },
  {
    index: 6,
    // headline: 'Focus On The Writing',
    // button: 'Get Focused',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0007.png'
  },
  {
    index: 7,
    // headline: 'New Fashion Apparel',
    // button: 'Shop now',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0008.png'

  },
  {
    index: 8,
    // headline: 'In The Wilderness',
    // button: 'Book travel',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0009.png'
  },
  {
    index: 9,
    // headline: 'For Your Current Mood',
    // button: 'Listen',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0010.png'
  },
  {
    index: 10,
    // headline: 'New Fashion Apparel',
    // button: 'Shop now',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0011.png'

  },
  {
    index: 11,
    // headline: 'In The Wilderness',
    // button: 'Book travel',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0012.png'
  },
  {
    index: 12,
    // headline: 'For Your Current Mood',
    // button: 'Listen',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0013.png'
  },
  {
    index: 13,
    headline: 'Focus On The Writing',
    button: 'Get Focused',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0014.png'
  },
  {
    index: 14,
    // headline: 'New Fashion Apparel',
    // button: 'Shop now',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0015.png'

  },
  {
    index: 15,
    // headline: 'In The Wilderness',
    // button: 'Book travel',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0016.png'
  },
  {
    index: 16,
    // headline: 'For Your Current Mood',
    // button: 'Listen',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0017.png'
  },
  {
    index: 17,
    // headline: 'New Fashion Apparel',
    // button: 'Shop now',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0018.png'

  },
  {
    index: 18,
    // headline: 'In The Wilderness',
    // button: 'Book travel',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0019.png'
  },
  {
    index: 19,
    // headline: 'For Your Current Mood',
    // button: 'Listen',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0020.png'
  },
  {
    index: 20,
    // headline: 'Focus On The Writing',
    // button: 'Get Focused',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0021.png'
  },
  {
    index: 21,
    // headline: 'New Fashion Apparel',
    // button: 'Shop now',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0022.png'

  },
  {
    index: 22,
    // headline: 'In The Wilderness',
    // button: 'Book travel',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0023.png'
  },
  {
    index: 23,
    // headline: 'For Your Current Mood',
    // button: 'Listen',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0024.png'
  },
  {
    index: 24,
    // headline: 'New Fashion Apparel',
    // button: 'Shop now',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0025.png'

  },
  {
    index: 25,
    // headline: 'In The Wilderness',
    // button: 'Book travel',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0026.png'
  },
  {
    index: 26,
    // headline: 'For Your Current Mood',
    // button: 'Listen',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0027.png'
  },
  {
    index: 27,
    // headline: 'Focus On The Writing',
    // button: 'Get Focused',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0028.png'
  },
  {
    index: 28,
    // headline: 'New Fashion Apparel',
    // button: 'Shop now',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0029.png'

  },
  {
    index: 29,
    // headline: 'In The Wilderness',
    // button: 'Book travel',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0030.png'
  },
  {
    index: 30,
    // headline: 'For Your Current Mood',
    // button: 'Listen',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0031.png'
  },
  {
    index: 31,
    // headline: 'New Fashion Apparel',
    // button: 'Shop now',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0032.png'

  },
  {
    index: 32,
    // headline: 'In The Wilderness',
    // button: 'Book travel',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0033.png'
  },
  // {
  //   index: 33,
  //   // headline: 'For Your Current Mood',
  //   // button: 'Listen',
  //   src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0034.png'
  // },
  {
    index: 33,
    // headline: 'Focus On The Writing',
    // button: 'Get Focused',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0035.png'
  },
  {
    index: 34,
    // headline: 'New Fashion Apparel',
    // button: 'Shop now',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0036.png'

  },
  {
    index: 35,
    // headline: 'In The Wilderness',
    // button: 'Book travel',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0037.png'
  },
  {
    index: 36,
    // headline: 'For Your Current Mood',
    // button: 'Listen',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0038.png'
  },
  {
    index: 37,
    // headline: 'New Fashion Apparel',
    // button: 'Shop now',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0039.png'

  },
  {
    index: 38,
    // headline: 'In The Wilderness',
    // button: 'Book travel',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0040.png'
  },
  {
    index: 39,
    // headline: 'For Your Current Mood',
    // button: 'Listen',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0041.png'
  },
  {
    index: 40,
    // headline: 'Focus On The Writing',
    // button: 'Get Focused',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0042.png'
  },
  {
    index: 41,
    // headline: 'Focus On The Writing',
    // button: 'Get Focused',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0043.png'
  },
  {
    index: 42,
    // headline: 'New Fashion Apparel',
    // button: 'Shop now',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0044.png'

  },
  {
    index: 43,
    // headline: 'In The Wilderness',
    // button: 'Book travel',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0045.png'
  },
  {
    index: 44,
    // headline: 'For Your Current Mood',
    // button: 'Listen',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0046.png'
  },
  {
    index: 45,
    // headline: 'New Fashion Apparel',
    // button: 'Shop now',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0047.png'

  },
  {
    index: 46,
    // headline: 'In The Wilderness',
    // button: 'Book travel',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0048.png'
  },
  {
    index: 47,
    // headline: 'For Your Current Mood',
    // button: 'Listen',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0049.png'
  },
  {
    index: 48,
    // headline: 'Focus On The Writing',
    // button: 'Get Focused',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0050.png'
  },
  {
    index: 49,
    // headline: 'Focus On The Writing',
    // button: 'Get Focused',
    src: 'https://kolorsimages.s3.us-west-1.amazonaws.com/0051.png'
  }
]



export const StyledButton = styled.button`
  border-radius: 50px;
  border: 2px solid white;
  // border: black;
  // border-style: solid;
  // background: radial-gradient(ellipse at center, rgb(247,175,157), rgb(241,126,47));
  background: none;
  margin-top: 2%;
  padding: 10px;
  color: white;
  width: 15rem;
  height: 4rem;
  cursor: pointer;
  // font-size: 1.7rem;
  text-transform: uppercase;
  opacity: 1;
  // transition:all .5s ease-in-out;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0..5) 0px 0px 0px 1px;
  @media only screen and (max-width: 40em) { 
    width: 8rem;
    height: 3rem;
    } /* max-width 640px, mobile-only styles, use when QAing mobile issues */
  :hover {
  background:white;
  color:#00373a;
  -webkit-box-shadow: 0px 0px 10px white;
  box-shadow: 0px 0px 10px white;
  animation: bgchange 5s linear infinite;

  }
  
  //   @keyframes bgchange{
  //   0%{
  //     background-color: rgba(255, 255, 255, 1);
  //   }
  
  //   50%{
  //     background-color: rgba(255, 255, 255, 0.3);
  //   }
  
  //   100%{
  //     background-color: rgba(255, 255, 255, 1);
  //   }
  // }
        
  // transition: background 200ms ease-in-out;
  // animation: glow 1s infinite, fadeIn 4s ease 0s 1 normal;
  // @keyframes glow{
  //   0%{
  //   box-shadow: 5px 5px 20px rgb(93, 52, 168),-5px -5px 20px rgb(93, 52, 168), 5px 5px 20px rgb(242, 134, 52)}
  //   50%{
  //   box-shadow: -5px -5px 20px rgb(242, 134, 52), 5px 5px 20px rgb(81, 224, 210),-5px -5px 20px rgb(81, 224, 210)
  //   }
  //   100%{
  //   box-shadow: 5px 5px 20px rgb(93, 52, 168),-5px -5px 20px rgb(93, 52, 168), 5px 5px 20px rgb(242, 134, 52)
  //   }
  // }
//   @keyframes fadeIn {
//     0% {opacity: 0}
//     100% {opacity: 1}
// }

// @-webkit-keyframes fadeIn {
//     0% {opacity: 0}
//     100% {opacity: 1}
// }

  // :focus {
  //   outline: 0;
  // }
  // :before, :after {
  //   position: absolute;
  //   content: '';
  //   display: block;
  //   width: 140%;
  //   height: 100%;
  //   left: -20%;
  //   z-index: 99;
  //   transition: all ease-in-out 0.5s;
  //   background-repeat: no-repeat;
  // }
  :before {
    display: none;
    top: -75%;
    background-image: radial-gradient(circle, #02063d 20%, transparent 20%), radial-gradient(circle, transparent 20%, #1155cc 20%, transparent 30%), radial-gradient(circle, #02063d 20%, transparent 20%), radial-gradient(circle, #1155cc 20%, transparent 20%), radial-gradient(circle, transparent 10%, #02063d 15%, transparent 20%), radial-gradient(circle, #1155cc 20%, transparent 20%), radial-gradient(circle, #02063d 20%, transparent 20%), radial-gradient(circle, #1155cc 20%, transparent 20%), radial-gradient(circle, #02063d 20%, transparent 20%);
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
  }
  :after {
    display: none;
    bottom: -75%;
    background-image: radial-gradient(circle, #1155cc 20%, transparent 20%), radial-gradient(circle, #02063d 20%, transparent 20%), radial-gradient(circle, transparent 10%, #1155cc 15%, transparent 20%), radial-gradient(circle, #02063d 20%, transparent 20%), radial-gradient(circle, #1155cc 20%, transparent 20%), radial-gradient(circle, #02063d 20%, transparent 20%), radial-gradient(circle, #1155cc 20%, transparent 20%);
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
  }
  :active {
    transform: scale(0.9);
    background-color: #FEFBFB;
    box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
    color: black;
  }
  // @keyframes wobble{
  //     25% {
  //       transform: rotate(15deg);
  //     }
  //     50% {
  //       transform: rotate(-30deg);
  //     }
  //     75% {
  //       transform: rotate(5deg);
  //     }
  //     100% {
  //       transform: rotate(0deg);
  //     }
  //   }
  // box-shadow: 00 15px 50px $blue,
  // 0 -15px 60px lighten($blue,30%),
  // inset 0 2px 2px lighten($blue,20%);
  // -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  // -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  // -webkit-transition:all 0.3s ease;
  // -webkit-touch-callout: none; 
  //   -webkit-user-select: none; 
  //      -moz-user-select: none; 
  //       -ms-user-select: none; 
  //           user-select: none;
  // :active {
  //   box-shadow: none;
  //   -webkit-box-shadow: none;
  //   -moz-box-shadow: none;
  //   -webkit-transform: scale(0.8);
  //   -webkit-transition:all 0.1s ease;
  //   outline: none;
  //   background: radial-gradient(ellipse at center, lighten($blue,15%), lighten($blue,10%));
  //   box-shadow: 0 15px 80px $blue,
  //               0 -15px 80px lighten($blue,30%),
  //               inset 0 -2px 2px lighten($blue,10%);
  // }
  // &:focus { outline: none; }
  // :hover {
  //   // animation-play-state: paused;
  //   // background-color: #fbd8e3;
  //   border-color: white;
  //   // color: gold;
  //   // background: var(--hover);
  // }
  // }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  
`;

export const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  @media (min-width: 767px) {
    width: 80%;
    height: 80%;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;



export const MainImg = styled.img`
  width: 42em;
  height: 42em;
  transform: translate(0%, -5%);
  @media (max-width: 767px) {
    width: 70px;
    height: 70px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const FooterImg = styled.img`
  width: 25%;
  height: 25%;
  display: flex;
  flex-direction: row;
  @media (min-width: 767px) {
    width: 150px;
    height: 35px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const FContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretched;
  width: 35%;
  margin: 50px;
  @media (max-width: 767px) {
    flex-direction: row;
    width: 60%;
    justify-content: space-between;
  }
`;

export const ButtonImg = styled.img`
  width: 55%;
  height: 55%;
  display: flex;
  flex-direction: row;
  @media (min-width: 767px) {
    width: 120px;
    height: 120px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const TopHeader = styled.div`
display: flex;
// background: #eee;
padding: 1rem;
margin-bottom: 0%;
text-align: center;
width: 100%;
height: 25px;
margin-top: 13%;
align-items: center;
justify-content: center;
position: relative;
// animation: fadeIn 1s ease 0s 1 normal;
// @keyframes fadeIn {
//   0% {opacity: 0}
//   100% {opacity: 1}
// }

@-webkit-keyframes fadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}
// @media only screen and (max-width: 40em) { 
//   // max-width: 70%;
//   // margin-right: 100%;
//   // background-color: black;
// } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
`;

export const CarouselContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(-70%, 5%);
  width: 30%;
  // height: 40%;
  margin: auto;
  // overflow: hidden;
  // background: #eee;
  // background-color: #eee8;
  @media (max-width: 767px) {
  //   flex-direction: row;
  //   width: 60%;
  //   justify-content: space-between;
  // }
`;

export const HighlightSpan = styled.span`
  padding: 0em 0em;
  background: none;
  color: white;
  font-family: Arial;
  font-size: 1.5rem;
  // line-height: .5rem;
  // box-shadow: .2em 0 0 #B976E2, -.2em 0 0 #B976E2;
  @media only screen and (max-width: 40em) { 
    font-size: 1rem;


  }
`;

export const HighlightSpan2 = styled.span`

  text-decoration: underline;
  // box-shadow: .2em 0 0 #F6722B, -.2em 0 0 #F6722B;
  @media only screen and (max-width: 40em) { 

  }
`;

export const HighlightSpan3 = styled.span`
  // padding: .05em 0;
  background: none;
  color: #ffffff;
  font-size: 1.1rem;
  // box-shadow: .2em 0 0 #F6722B, -.2em 0 0 #F6722B;
  @media only screen and (max-width: 40em) { 
    font-size: .7rem;
  }
  @media all and (min-width: 480px) and (max-width: 768px) { 
    font-size: 1rem;
  } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
`;

export const SpanThin = styled.span`
  font-family: Bebas;
  font-size: 1.5rem;
  @media only screen and (max-width: 40em) { 
    font-size: .6rem;
  }
  @media all and (min-width: 480px) and (max-width: 768px) { 
    font-size: .9rem;
  } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
`;

export const TextSpan = styled.span`
  font-size: 1.5rem;
  @media only screen and (max-width: 40em) { 
  font-size: 1.1rem;
  line-height: 1em;
  }
`;

export const TextSpan2 = styled.span`
  font-size: 1.3rem;
  
  // @media only screen and (max-width: 40em) { 
  // font-size: .8rem;
  // // line-height: 1em;
  // }
`;


export const SubSpan = styled.span`
  font-size: 1.7rem; 
  line-height: 1rem; 
  vertical-align: baseline; 
  margin-right: -5px;
  @media only screen and (max-width: 40em) { 
    font-size: 1.2rem;
    margin-right: -2px;
    vertical-align: baseline;
  }
`;

export const Card = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
  grid-auto-rows: min-content max-content auto;
  // grid-template-rows: repeat(auto-fit, minmax(auto, 1fr);
  // grid-template-rows: repeat(1, 1fr);
  gap: 0px;
  // flex-direction: column;
  background-color: rgba(29, 28, 26, 0.85);
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  // height: var(--card-height);
  padding: 0%;
  // left: 10%;
  border: 2px solid white;

  position: relative;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;

  // opacity: 80%;
  font-size: 1.5em;
  // color: rgb(88 199 250 / 0%);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.051),
  0px 0px 7.2px rgba(255, 255, 255, 0.073), 0px 0px 13.6px rgba(255, 255, 255, 0.09),
  0px 0px 24.3px rgba(255, 255, 255, 0.107), 0px 0px 45.5px rgba(255, 255, 255, 0.129),
  0px 0px 109px rgba(255, 255, 255, 0.18);
  // animation: fadeUp 3s ease 0s 1 normal;
  // @keyframes fadeUp {
  //   from {
  //     opacity: 0;
  //     transform: translate3d(0,10%,0);
  //   }
  //   to {
  //     opacity: 1;
  //     transform: translate3d(0%,0,0);
  //   }
  // }
@media only screen and (max-width: 40em) { 
  width: 100%;
  transform: translateX(0%);
  animation: none;
  // grid-template-columns: repeat(1, 20fr);
  // grid-template-rows: 1fr 1fr 1fr 1fr 3fr;
  // grid-template-columns: 1fr
}
`;

export const CardInfo = styled.div`
background-color: rgba(29, 28, 26, 0.85);
backdrop-filter: blur(5px);
  width: 112%;
  // height: var(--card-height);
  padding: 2%;
  left: 0;
  border: 2px solid white;

  position: relative;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  // opacity: 80%;
  font-size: 1.5em;
  // color: rgb(88 199 250 / 0%);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.051),
  0px 0px 7.2px rgba(255, 255, 255, 0.073), 0px 0px 13.6px rgba(255, 255, 255, 0.09),
  0px 0px 24.3px rgba(255, 255, 255, 0.107), 0px 0px 20.5px rgba(255, 255, 255, 0.129),
  0px 0px 109px rgba(255, 255, 255, 0.18);
  animation: fadeUp 6s ease 0s 1 normal;
  @keyframes fadeUp {
    from {
      opacity: 0;
      transform: translate3d(0,10%,0);
    }
    to {
      opacity: 1;
      transform: translate3d(0%,0,0);
    }
  }
}
@media only screen and (max-width: 40em) { 
  width: 123%;
  // transform: translate(-.5%, 45%);
  animation: none;
}
`;

export const CoinContainer = styled.div`
  display: flex;
  // flex-direction: row;
  grid-row: span 3 / auto;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-right: 2px solid white;
  // border-top: 2px solid white;
  // border-bottom: 10px solid transparent;
  // border-radius: 10px;
  // margin: -16% 0px 0px 0%;
  // padding: 0 0px 0 0;
  // transform: translate(-99%, 40.7%);
  @media only screen and (max-width: 40em) { 
    // border-right: 2px solid white;
    // width: 100%;
    // grid-column: 1;
    // grid-row: 1;
    // padding-bottom: 5%;
    // padding-top: 2%;
    // grid-area: 1 / 1 / 2 / 2;
    // grid-row-start: 1;
    // grid-column-start: 1;
    // grid-row-end: 2;
    // grid-column-end: 2;
    // transform: translateX(-15%);
  }
  `;

  export const LogoImg = styled.img`
  width: 22rem;
  height: 22rem;
  padding: 20px 20px 20px 20px;
  @media only screen and (max-width: 40em) { 
    width: 6rem;
    height: 6rem;
    padding: 0px, 0px, 0px, 0px;
  } /* max-width 640px, mobile-only styles, use when QAing mobile issues */
  @media only screen and (min-width: 40.063em) and (max-width: 64em) { 
    width: 9rem;
    height: 9rem;
    padding: 0px 0px 0px 0px;
  } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
`;

  export const ImgContainer = styled.div`
  // display: flex;
  display: flex;
  // flex-direction: row;
  width: 80%;
  height: 80%;
  justify-content: center;
  align-items: center;
  // margin: -21% 0px 0px 0%;
  padding: 0 0px 0 0;

  // transform: translate(-99%, 40.7%);
  @media only screen and (max-width: 40em) { 
    // transform: translateX(-15%);
  }

  `;



// export const Canvas = props => {
//   const canvasRef = useRef(null)
  
//   useEffect(() => {
//   // const canvas = document.querySelector('#canva')
//   let tl = gsap.timeline({ease:Power2.in});
//   // const c = canva.getContext('2d')
//   const canvas = canvasRef.current
//   const context = canvas.getContext('2d')
//   canvas.height = window.window.innerHeight
//   canvas.width = window.window.innerWidth
  
//   const btc = document.querySelector('#btc')
  
//   let _particles = []
  
//   class Particles {
//     constructor(x,y,pSize,color){
//       this.x = x
//       this.y = y
//       this.pSize = pSize
//       this.color = color
//       this.mass = Math.random() * 5 -2.5
//       this.dir = Math.random() * 2
      
//     }
//     draw(){
//       context.beginPath()
//       context.arc(this.x,this.y,this.pSize,0,Math.PI *2)
//       context.fillStyle = this.color
//       context.fill()
      
//     }
//     update(){
//       this.y -= this.mass 
//       this.x +=  this.dir
//       if(this.pSize > 0.1){
//         this.pSize -= 0.2
//       }
      
//     }
//   }
  
  
//   let int 
//   function click(){
//     let basic = btc.getBoundingClientRect()
//     let colors = ['#ff7070', '#b4ffa5', '#ba90fa', '#6b84e5'];
//     let size = Math.random() * 20 + 10
//     let x = basic.x + Math.random() * (40 - 40) + 40
//     let y = basic.y + 50
//     int = setInterval(() => {
//           let color = colors[Math.floor(Math.random() * colors.length)];
  
//        _particles.push(new Particles(x,y,size,color))
//   }, 50);
  
//     tl.to('#btc',{scale:0.8,duration:0.05})
//       .to('#btc',{scale:1,duration:0.05,onComplete:()=>{
//              clearInterval(int)
  
//       }})
  
//   }
  
//   function spawn(){
//     _particles.forEach((p,index)=>{
     
//       p.draw()
//       p.update()
    
//       if(p.pSize < 1){
//         _particles.splice(index,1)
          
     
//       }
//     })
//   }
  
  
//   function anima(){
//   context.clearRect(0,0,canvas.width,canvas.height)
    
//       btc.addEventListener('click',click)
  
  
//     spawn()
//       requestAnimationFrame(anima) 
//   }
//   anima() 
//   }, [])
  
//   return <canvas ref={canvasRef} {...props}/> }


function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("");
  const [claimingNft, setClaimingNft] = useState(false);
  const awaitCalc = data.cost * 10e-19; 
  const [mintAmount, setMintAmount] = useState(1);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
    },
    MAX_SUPPLY: 1000,
  });
  // const [animate, setAnimate] = useState(false);

  // const handleClick = () => setAnimate(!animate);

  // async function awaitCalc() {
  //   for (data.cost * 10e-19); 
  // }

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback("Now minting your Soulection Community Collectible. Please confirm the transaction in Metamask.");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(_amount)
      .send({
        gasLimit: "285000",
        // to: "0x2d2404314C704b6F5D8C030d3259F224d500ca99",
        to: "0x7C730E38f83Abb3fFe924d12d1aab58ceDAEb093",
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((awaitCalc * _amount).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          "Your Soulection Collectible has minted successfully. You can view it on https://testnets.opensea.io"
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);



  // const [imageIndex, setImageIndex] = useState(0);
  // const small = window.matchMedia('(max-width: 600px)');

  // const settings = {
  //   infinite: true,
  //   lazyLoad: true,
  //   speed: 300,
  //   dots: false,
  //   variableWidth: true,
  //   adaptiveHeight: true,
  //   focusOnSelect: true,
  //   cssEase: 'linear',
  //   slidesToShow: 3,
  //   centerMode: true,
  //   centerPadding: 0,
  //   touchMove: true,
  //   nextArrow: <NextArrow />,
  //   prevArrow: <PrevArrow />,
  //   beforeChange: (current, next) => setImageIndex(next),

  //             responsive: [                        
  //             {
  //               breakpoint: 576,
  //               settings: {
  //                 centerMode: false,
  //                 variableWidth: false,
  //               }
  //             },
  //         ]
  // };

  // if (small.matches) {
  //   settings.slidesToShow = 1
  // }

  return (
    <s.Screen style={{ 
        backgroundColor: "#171615"
        }}>
      <s.Container flex={1} ai={"center"} style={{ padding: 10 }}>
 
        <ResponsiveWrapper flex={1} style={{ padding: 12 }}>

        <s.ContainerHeader>  
        <s.TextHeaderMB style={{ textAlign: "center", margin: 0, fontWeight: 600, fontFamily: "conthraxsb", textTransform: "uppercase" }}>
                SOULECTION+ <br/>
            </s.TextHeaderMB>
            <s.TextHeaderMB style={{ textAlign: "center", margin: 0, fontWeight: 600, fontFamily: "conthraxsb", textTransform: "uppercase" }}>
                SOULECTION+ <br/>
            </s.TextHeaderMB>
            <s.TextHeaderMB style={{ textAlign: "center", margin: 0, fontWeight: 600, fontFamily: "conthraxsb", textTransform: "uppercase" }}>
                SOULECTION+ <br/>
            </s.TextHeaderMB>
          {/* <s.TextHeaderSub style={{ textAlign: "center", margin: 0, fontFamily: "futura" }}>
                 COMMUNITY DIGITAL COLLECTIBLE 
          </s.TextHeaderSub> */}

        {/* <TopHeader> */}
          
          
              {/* <LogoImg alt={"logo"} 
                src={i2}
                flex={1}
                fd={"row"}
                ai={"center"}
                jc={"center"} />  */}
          {/* </TopHeader> */}
        {/* <s.SpacerLarge /> */}


          {/* <s.TextHeader style={{ textAlign: "center", margin: 0, fontFamily: "Bebas" }}>
                Presents
          </s.TextHeader> */}
          {/* <s.TextHeaderSub style={{ textAlign: "center", margin: 0, fontFamily: "futura" }}>
                - A SOULECTION<SubSpan>+&nbsp;</SubSpan>
                    COLLECTIBLE - <br />
          </s.TextHeaderSub> */}
          {/* <s.SpacerSmall /> */}
          {/* <s.SpacerLarge /> */}

          </s.ContainerHeader>
          
              <s.SpacerLarge />
              <s.SpacerLarge />
              <s.SpacerSmall />
          
          {/* <CarouselContainer> */}
          {/* <MainImg 
              flex={1}
              jc={"center"}
              ai={"center"}
              alt={"main-image"} src={i1} />  */}
          
        {/* <s.SpacerMedium />
            {/* <Slider heading="Slider" slides={slideData} /> */}
          {/* </CarouselContainer > */}

      {/* <s.SpacerLarge /> */}
          
          {/* <s.SpacerMedium /> */}
         

        {/* <s.ContainerMarquee>
          
        </s.ContainerMarquee> */}
        {/* <s.SpacerSmall />
        <s.SpacerLarge /> */}
        
          <Card>
            <CoinContainer>
              <ImgContainer>
              {/* <LogoImg alt={"logo"} 
                src={i3}
                flex={1}
                fd={"row"}
                ai={"center"}
                jc={"center"} />  */}
              <LogoImg alt={"logo"} 
                src={i2} /> 
              {/* <LogoImg alt={"logo"} 
                src={i4}
                flex={1}
                fd={"row"}
                ai={"center"}
                jc={"center"} />  */}
                </ImgContainer>
            </CoinContainer>
            {/* <s.SpacerLarge /> */}

            <s.ContainerHeaderBox>
                <s.TextHeader style={{ textAlign: "left", margin: 0, fontFamily: "Bebas" }}>
                {/* <HighlightSpan>NAME <br /></HighlightSpan> */}
                COMMUNITY <br />MINT.001
                </s.TextHeader>
            </s.ContainerHeaderBox>
  
            <s.ContainerMarquee>
            <s.TextSubHead style={{ textAlign: "left", margin: 0, fontFamily: "Bebas", textTransform: "uppercase" }}>
                {/* <HighlightSpan>Specifications</HighlightSpan> <br /> */}
                <SpanThin>Cost:</SpanThin> Free (Excluding Polygon Gas Fees) <br />
                <SpanThin>Tokens_Minted:</SpanThin> {data.totalSupply} 
                    <br />
                {/* <SpanThin>Mint_Dates: </SpanThin>
                   02/18/2022 - 02/25/2022 <br /> */}
                <Countdown />

                {/* <s.TextDescription>
                    <TextSpan2></TextSpan2>
                </s.TextDescription> */}

            {/* <SubSpan>+&nbsp;</SubSpan> */}
            {/* {/* <TextSpan>Produced by x<br /></TextSpan> */}
            {/* <TextSpan>Mint Dates: <br />02/18 - 02/25 <br /></TextSpan>  */}
            {/* <s.SpacerLarge />
            <s.SpacerLarge /> */}
             </s.TextSubHead>
            </s.ContainerMarquee>

          <s.TextSummaryBox>
          <s.TextSummary style={{ textAlign: "left", margin: 0, fontFamily: "Bebas", textTransform: "uppercase" }}>
          {/* <HighlightSpan>Memo <br /></HighlightSpan> */}
            Soulection has a long history of utilizing the internet to the advantage of our artists and culture. 
            We believe web3 & blockchain technologies offer a rare opportunity to connect directly to our core supporters, and create systems in line with our ethos that can withstand the test of time.
            <br /> <br />
            We partnered with digital artist <HighlightSpan2>ROHO</HighlightSpan2> to create a free collectible for our community & day one supporters. To make it as affordable and environmentally friendly as possible, 
            we chose to use the Polygon network which minimizes the cost, energy, and carbon impact on the blockchain.
            <br /><br />
            <HighlightSpan3>Details: The mint is open for 7 days to make sure everyone has a chance to get theirs.  
              If you need any technical assistance beyond our step by step guide, please join our discord 
              & and hit the web3 channel to reach out!</HighlightSpan3>
            {/* <SubSpan>+&nbsp;</SubSpan> */}
            {/* {/* <TextSpan>Produced by x<br /></TextSpan> */}
            {/* <TextSpan>Mint Dates: <br />02/18 - 02/25 <br /></TextSpan>  */}
            {/* <s.SpacerLarge />
            <s.SpacerLarge /> */}
          </s.TextSummary>

          </s.TextSummaryBox>


          {/* <s.SpacerMedium /> */}

            <s.ContainerButton
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{ backgroundColor: "none", padding: 0 }}
          >

               {/* <s.TextSubHead style={{ textAlign: "center", margin: 9, fontFamily: "Argentum", textTransform: "uppercase" }}> */}
            {/* SOULECTION+ COMMUNITY TOKEN <br /> */}
            {/* <SubSpan>+&nbsp;</SubSpan> */}
            {/* AN Original Digital Collectible <br />  */}
            {/* <TextSpan>Produced by x<br /></TextSpan>
            <TextSpan>Designed by x <br /></TextSpan> */}

            {/* <s.SpacerLarge /> */}
            {/* <s.SpacerSmall /> */}

          {/* </s.TextSubHead> */}

            <s.TextDescription style={{ textAlign: "center", fontFamily: "Archivo" }}>
              Please make sure you are connected to the Polygon Mainnet.
            </s.TextDescription>

            {Number(data.totalSupply) == 200 ? (
              <>
                <s.TextTitle style={{ textAlign: "center" }}>
                  The sale has ended.
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextHeader style={{ textAlign: "center" }}>
                  You can find the Soulection Collectible (SOUL) on the secondary market @ {" "}
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={"https://testnets.opensea.io/collection/testbear-2"}
                  >
                    https://www.opensea.io
                  </a>
                </s.TextHeader>

              </>
            ) : (
              <>
              
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    {/* <s.TextDescription style={{ textAlign: "center", fontSize: "2rem" }}>
                      Click the button below to connect your Metamask wallet.
                    </s.TextDescription> */}
                <s.Container 
                      ai={"center"} jc={"center"} fd={"column"}>
                    <StyledButton
                      id="click-me"
                      // id="btc"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                    <s.TextButton style={{ textAlign: "center", fontWeight: 600, fontFamily: "Bebas" }}>
                    CONNECT
                    </s.TextButton>
                    </StyledButton>
                    </s.Container>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "center" }}>
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <s.Container 
                      // id="btc"
                      ai={"center"} jc={"center"} fd={"row"}>
                    <StyledButton
                      id="click-me"
                      // id="btc"
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(1);
                        getData();
                      }}
                    >
                      {claimingNft ? 
                      <s.TextButton style={{ textAlign: "center", margin: 2, fontWeight: 600, fontFamily: "Bebas" }}>
                      •••
                      </s.TextButton> : 
                      <s.TextButton style={{ textAlign: "center", margin: 2, fontWeight: 600, fontFamily: "Bebas" }}>
                    MINT
                    <br />
                    </s.TextButton>
                      }
                    </StyledButton>
                  </s.Container>
                )}
              </>
            )}



            
                {/* <s.TextTitle style={{ textAlign: "center" }}>
          
                  Connect Wallet to see price (excludes gas fee). 
              </s.TextTitle> */}
                {/* <s.TextPrice style={{ textAlign: "center", fontFamily: "Bebas" }}>
                  {data.cost * 10e-19} Polygon MATIC 
                </s.TextPrice> */}
 
    

              {/* <s.SpacerLarge /> */}
            {/* <s.SpacerSmall /> */}

          </s.ContainerButton>  
           
             <s.ContainerFeedback>
          <s.TextFeedback style={{ textAlign: "center", fontFamily: "Bebas" }}>
                  {feedback} <br />
                </s.TextFeedback>
                </s.ContainerFeedback>

            {/* <s.ContainerCountdown>        
              <s.TextSubHead style={{ textAlign: "center", margin: 0, fontFamily: "Bebas", textTransform: "uppercase" }}>
          Over the past nine years, their grassroots expansion is a model of what happens when one stays true to their roots in the constantly evolving digital age of music. From putting out independent releases with producers like Ta-ku, Sango, Dpat, and Atu on Bandcamp to being the first collective invited to play Coachella, Soulection’s growth has been organic and fruitful. 
             <SubSpan>+&nbsp;</SubSpan> 
             <TextSpan>Produced by x<br /></TextSpan> 
            <TextSpan>Mint Dates: <br />02/18 - 02/25 <br /></TextSpan> 
            <Countdown />
            <s.SpacerLarge />
            <s.SpacerLarge />
          </s.TextSubHead>
          </s.ContainerCountdown> */}

          </Card>
          
          {/* <s.SpacerLarge /> */}
          {/* <s.SpacerSmall /> */}


        </ResponsiveWrapper>
        {/* <s.SpacerLarge />
            <s.SpacerLarge />
            <s.SpacerLarge />  */}
        {/* <TopHeader> */}
          
          
              {/* <LogoImg alt={"logo"} 
                src={i2}
                flex={1}
                fd={"row"}
                ai={"center"}
                jc={"center"} />  */}
          {/* </TopHeader> */}
        {/* <s.SpacerLarge /> */}


          {/* <s.TextHeader style={{ textAlign: "center", margin: 0, fontFamily: "Bebas" }}>
                Presents
          </s.TextHeader> */}
          {/* <s.TextHeaderSub style={{ textAlign: "center", margin: 0, fontFamily: "futura" }}>
                - A SOULECTION<SubSpan>+&nbsp;</SubSpan>
                    COLLECTIBLE - <br />
          </s.TextHeaderSub> */}
          <s.SpacerSmall />

        <s.BottomContainer jc={"center"} ai={"center"} style={{  }}>
          <CardInfo>
            <s.TextSubHeadNote style={{ textAlign: "center", margin: 9, fontFamily: "Archivo", textTransform: "uppercase" }}>
              Click here for a step-by-step guide on how to mint this <br /> free digital collectible
            </s.TextSubHeadNote>

          </CardInfo>
          {/* <s.TextDescription style={{ textAlign: "center", fontFamily: "Argentum", fontSize: ".7rem" }}>
          We are holding back 20 digital collectibles<br />to distribute internally to the creatives who helped bring this project to life.
          </s.TextDescription> */}

          <FContainer>
                <AnimatedSocialIcon
                    brandName="discord"
                    url="https://discord.gg/soulection"
                    animation="bounce"
                    defaultColor="#D8D8D8"
                    hoverColor="#5865F2"
                    width="2em"
                    animationDuration={1.5}
                    style={{padding: '0'}}
                    />
                  {/* <AnimatedSocialIcon
                    brandName="twitch"
                    url="https://www.twitch.tv/soulection"
                    animation="bounce"
                    defaultColor="#D8D8D8"
                    hoverColor="#6441A5"
                    width="2em"
                    animationDuration={1.5}
                    style={{padding: '0'}}
                    /> */}
                <AnimatedSocialIcon
                    brandName="instagram"
                    url="https://www.instagram.com/soulection/"
                    animation="bounce"
                    defaultColor="#D8D8D8"
                    hoverColor="#8a3ab9"
                    width="2em"
                    animationDuration={1.5}
                    style={{padding: '0'}}
                    />
                  <AnimatedSocialIcon
                    brandName="twitter"
                    url="https://twitter.com/soulection"
                    animation="bounce"
                    defaultColor="#D8D8D8"
                    hoverColor="#1DA1F2"
                    width="2em"
                    animationDuration={1.5}
                    style={{padding: '0'}}
                    />
          </FContainer>  
        </s.BottomContainer>
      </s.Container>
    </s.Screen>
    
  );
}

export default App;
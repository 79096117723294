import styled from "styled-components";
import img from "../assets/soulection-vinyl.gif";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--dark-grey);
  // background-image: url(${img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "center")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  // transform: translateY(-50%);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const BottomContainer = styled.div`
  display: flex;
  position: relative;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "center")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 70%;
  // transform: translateY(-30%);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const TextTitle = styled.p`
  color: var(--white);
  font-size: .6rem;
  font-weight: 500;
  line-height: 1.6;
  font-family: "Argentum";
  @media only screen and (max-width: 40em) { 
    font-size: .6rem;
         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */   

`;

export const TextSubTitle = styled.p`
  color: var(--white);
  font-size: 1.2rem;
  line-height: 1.6;
  font-family: "Argentum";

  
`;

export const TextDescription = styled.p`
  color: var(--white);
  font-size: .7rem;
  text-align: right;
  padding-top: 3%;
  // line-height: 1.6;
  font-family: "Bebas";
  letter-spacing: .04em;
  @media only screen and (max-width: 40em) { 
    font-size: .5rem;
         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */   



  // animation: fadeUp 1s ease 0s 1 normal;
  // @keyframes fadeUp {
  //   from {
  //     opacity: 0;
  //     transform: translate3d(0,10%,0);
  //   }
  //   to {
  //     opacity: 1;
  //     transform: translate3d(0,0,0);
  //   }
  `;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const TextHeader = styled.p`
font-size: 4rem;
line-height: 1;
letter-spacing: .03em;
position: relative;
color: white;
// border-top: 2px solid #D8D8D8;
// border-bottom: 2px solid #D8D8D8;
padding: 3% 4%;
width: 100%;
// transform: translateY(-15%);
align-items: center;
@media only screen and (max-width: 40em) { 
  font-size: 2rem;
  line-height: 1.2;
  // padding-bottom: 8%;
  } /* max-width 640px, mobile-only styles, use when QAing mobile issues */ 
@media all and (min-width: 480px) and (max-width: 768px) { 
    font-size: 3rem;
  } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
text-shadow: 1px 2px 3px #DDEEFF,
0 0 0 black,
-1px 1px 0 #DDEEFF,
// 1px 1px 0 white;

// color: rgba(254, 103, 2, .8);

&:hover {
  &:before {
      animation: glitch .25s cubic-bezier(.25, .46, .45, .94) both infinite
  }
  &:after {
      animation: glitch .25s cubic-bezier(.25, .46, .45, .94) reverse both infinite
  }
}
*/
}

@keyframes glitch {
0% {
    transform: translate(-5px, 5px)
}
30% {
    transform: translate(-5px, -5px)
}
55% {
    transform: translate(5px, 5px)
}
80% {
    transform: translate(5px, -5px)
}
}

`;

  export const TextHeaderSub = styled.p`
  line-height: 1.6;
  font-family: "Cabin";
  letter-spacing: .08em;
  color: white;
  border: 1px solid white;
  border-radius: 50px;
  padding: 1%;
  font-size: 1.1rem;
  transform: translateY(100%);
  @media only screen and (max-width: 40em) { 
    font-size: .7rem;
         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */   
  // text-shadow: 1px 1px 0 hotpink,
  // 2px 2px 0 pink,
  // 3px 3px 0 purple,
  // 4px 4px 0 lightpink;
  // background-image: -webkit-linear-gradient(92deg, #8510d8, #f35626);
	// -webkit-background-clip: text;
	// -webkit-text-fill-color: transparent;
	// -webkit-animation: hue 10s infinite ease-in-out;
  // @keyframes hue {
  //   from {
  //     -webkit-filter: hue-rotate(0deg);
  //   }
  //   to {
  //     -webkit-filter: hue-rotate(-360deg);
  //   }
  // @media (max-width: 800px) {
  //   font-size: .7rem;
  //   // transform: translate(-3%, 130%);
  //   }
  `;

  export const TextHeaderMB = styled.p`
  // line-height: .75;
  letter-spacing: .01em;
  color: #ffffff;
  // border-bottom: 10px dotted;
  padding: 0;
  font-size: 7.8rem;
  // margin-top: 10rem;
  // transform: translateY(-15%);
  animation: glitch1 15.5s infinite;
  line-height: 0;
  :nth-child(2) {
    color: #67f3da;
    animation: glitch2 15.5s infinite;
  }
  
  :nth-child(3) {
    color: #f16f6f;
    animation: glitch3 15.5s infinite;
  }
  /*Keyframes*/
  
  @keyframes glitch1 {
    0% {
      transform: none;
      opacity: 1;
    }
    7% {
      transform: skew(-0.5deg, -0.7deg);
      opacity: 0.75;
    }
    10% {
      transform: none;
      opacity: 1;
    }
    27% {
      transform: none;
      opacity: 1;
    }
    30% {
      transform: skew(0.8deg, -0.1deg);
      opacity: 0.75;
    }
    35% {
      transform: none;
      opacity: 1;
    }
    52% {
      transform: none;
      opacity: 1;
    }
    55% {
      transform: skew(-1deg, 0.2deg);
      opacity: 0.75;
    }
    50% {
      transform: none;
      opacity: 1;
    }
    72% {
      transform: none;
      opacity: 1;
    }
    75% {
      transform: skew(0.4deg, 1deg);
      opacity: 0.75;
    }
    80% {
      transform: none;
      opacity: 1;
    }
    100% {
      transform: none;
      opacity: 1;
    }
  }
  
  @keyframes glitch2 {
    0% {
      transform: none;
      opacity: 0.25;
    }
    7% {
      transform: translate(-2px, -3px);
      opacity: 0.5;
    }
    10% {
      transform: none;
      opacity: 0.25;
    }
    27% {
      transform: none;
      opacity: 0.25;
    }
    30% {
      transform: translate(-5px, -2px);
      opacity: 0.5;
    }
    35% {
      transform: none;
      opacity: 0.25;
    }
    52% {
      transform: none;
      opacity: 0.25;
    }
    55% {
      transform: translate(-5px, -1px);
      opacity: 0.5;
    }
    50% {
      transform: none;
      opacity: 0.25;
    }
    72% {
      transform: none;
      opacity: 0.25;
    }
    75% {
      transform: translate(-2px, -3px);
      opacity: 0.5;
    }
    80% {
      transform: none;
      opacity: 0.25;
    }
    100% {
      transform: none;
      opacity: 0.25;
    }
  }
  
  @keyframes glitch3 {
    0% {
      transform: none;
      opacity: 0.25;
    }
    7% {
      transform: translate(2px, 3px);
      opacity: 0.5;
    }
    10% {
      transform: none;
      opacity: 0.25;
    }
    27% {
      transform: none;
      opacity: 0.25;
    }
    30% {
      transform: translate(5px, 2px);
      opacity: 0.5;
    }
    35% {
      transform: none;
      opacity: 0.25;
    }
    52% {
      transform: none;
      opacity: 0.25;
    }
    55% {
      transform: translate(5px, 1px);
      opacity: 0.5;
    }
    50% {
      transform: none;
      opacity: 0.25;
    }
    72% {
      transform: none;
      opacity: 0.25;
    }
    75% {
      transform: translate(2px, 6px);
      opacity: 0.5;
    }
    80% {
      transform: none;
      opacity: 0.25;
    }
    100% {
      transform: none;
      opacity: 0.25;
    }
  }

  @media only screen and (max-width: 40em) { 
    font-size: 2.3rem;
         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */   

         @media all and (min-width: 480px) and (max-width: 768px) { 
           font-size: 2.5rem;
         }

         color: #D0D3D4;
         text-shadow:
           0 1px 0px #283747,
           1px 0 0px #283747,
           1px 2px 1px #283747,
           2px 1px 1px #283747,
           2px 3px 2px #283747,
           3px 2px 2px #283747,
           3px 4px 2px #283747,
           4px 3px 3px #283747,
           4px 5px 3px #283747,
           5px 4px 2px #283747,
           5px 6px 2px #283747,
           6px 5px 2px #283747,
          //  6px 6px 1px #283747,
          //  6px 6px 1px #283747,
          //  6px 7px 0px #283747,
          //  6px 7px 0px #283747;
         letter-spacing:1px;
         font-weight: bold;
       
  // text-shadow: 1px 1px 0 #809bce   ,
  // 2px 2px 0 #95b8d1 ,
  // 3px 3px 0 #b8e0d2 ,
  // 4px 4px 0 #a7bed3  ;
  // background-image: -webkit-linear-gradient(92deg, #d1cfe2 , #52b2cf );
	// -webkit-background-clip: text;
	// -webkit-text-fill-color: transparent;
	// -webkit-animation: hue 10s infinite ease-in-out;

  // @keyframes hue {
  //   from {
  //     -webkit-filter: hue-rotate(0deg);
  //   }
  //   to {
  //     -webkit-filter: hue-rotate(-360deg);
  //   }

  `;

  export const TextButton = styled.p`
  line-height: 1;
  letter-spacing: .15em;
  color: #fffff;
  font-size: 2.7rem;
  @media only screen and (max-width: 40em) { 
    font-size: 1.2rem;
         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */ 
	// background-image: -webkit-linear-gradient(92deg, #8510d8, #f35626);
	// -webkit-background-clip: text;
	// -webkit-text-fill-color: transparent;
	// -webkit-animation: hue 1s infinite ease-in-out;
  // @keyframes hue {
  //   from {
  //     -webkit-filter: hue-rotate(0deg);
  //   }
  //   to {
  //     -webkit-filter: hue-rotate(-360deg);
  //   }
  // }
  
  `;

  export const TextSubHead = styled.p`
  font-size: 1.5rem;
  line-height: 1.1;
  letter-spacing: .05em;
  position: relative;
  color: white;
  // border-top: 2px solid #D8D8D8;
  // border-bottom: 2px solid #D8D8D8;
  // border-left: 2px solid white;
  padding: 4% 4%;
    width: 100%;
  // transform: translateY(-15%);
  align-items: center;
  @media only screen and (max-width: 40em) { 
    border-left: none;
    font-size: .8rem;
    line-height: 1.7;
    // padding-bottom: 8%;
  } /* max-width 640px, mobile-only styles, use when QAing mobile issues */ 

  @media all and (min-width: 480px) and (max-width: 768px) { 
    font-size: 1rem;
  } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */         
        
 text-shadow: 1px 2px 3px #DDEEFF,
  0 0 0 black,
  -1px 1px 0 #DDEEFF,
  // 1px 1px 0 white;

// color: rgba(254, 103, 2, .8);
  
  &:hover {
    &:before {
        animation: glitch .25s cubic-bezier(.25, .46, .45, .94) both infinite
    }
    &:after {
        animation: glitch .25s cubic-bezier(.25, .46, .45, .94) reverse both infinite
    }
  }
  */
  }

  @keyframes glitch {
  0% {
      transform: translate(-5px, 5px)
  }
  30% {
      transform: translate(-5px, -5px)
  }
  55% {
      transform: translate(5px, 5px)
  }
  80% {
      transform: translate(5px, -5px)
  }
  }
  
  `;

  export const TextSummary = styled.p`
  font-size: 1.5rem;
  line-height: 1.2;
  letter-spacing: .05em;
  position: relative;
  color: white;
  border-top: 2px solid #D8D8D8;
  border-bottom: 2px solid #D8D8D8;
  padding: 3% 3%;
  width: 100%;
  // transform: translateY(-15%);
  align-items: center;
  @media only screen and (max-width: 40em) { 
    font-size: .9rem;
    line-height: 1.7;
    // padding-bottom: 8%;
         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */ 

      @media all and (min-width: 480px) and (max-width: 768px) { 
           font-size: 1.1rem;
         }

        } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
  text-shadow: 1px 2px 3px #DDEEFF,
  0 0 0 black,
  -1px 1px 0 #DDEEFF,
  // 1px 1px 0 white;

// color: rgba(254, 103, 2, .8);
  
  &:hover {
    &:before {
        animation: glitch .25s cubic-bezier(.25, .46, .45, .94) both infinite
    }
    &:after {
        animation: glitch .25s cubic-bezier(.25, .46, .45, .94) reverse both infinite
    }
  }
  */
  }

  @keyframes glitch {
  0% {
      transform: translate(-5px, 5px)
  }
  30% {
      transform: translate(-5px, -5px)
  }
  55% {
      transform: translate(5px, 5px)
  }
  80% {
      transform: translate(5px, -5px)
  }
  }
  
  `;

  export const TextSubHeadNote = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  font-family: "Cabin";
  letter-spacing: .02em;
  position: relative;
  color: white;
  :hover {
    color: pink;
  }
  float: left;

  @media only screen and (max-width: 40em) { 
    font-size: .7rem;
    animation: fadeUp 10s ease 0s 1 normal;
    @keyframes fadeUp {
      from {
        opacity: 0;
        transform: translate3d(-5%,10%,0);
      }
      to {
        opacity: 1;
        transform: translate3d(-5%,0,0);
      }
         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */ 
  text-shadow: 1px 2px 3px #DDEEFF,
  0 0 0 black,
  -1px 1px 0 #DDEEFF,
  // 1px 1px 0 white;

// color: rgba(254, 103, 2, .8);
  
  }
  `;

  export const TextFeedback = styled.p`
  font-size: 1.5rem;
  line-height: 1.6;
  font-family: "futura";
  letter-spacing: .04em;
  position: relative;
  // border: 1px solid black;
  padding: 10px;
  color: white;
  margin: 0px 0px 5px 0px;
  @media only screen and (max-width: 40em) { 
    font-size: .8rem;
         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */

//   background: linear-gradient(
//     70deg,
//     #0ebeff,
//     #ffdd40,
//     #ae63e4,
//     #47cf73,
//     #0ebeff,
//     #ffdd40,
//     #ae63e4,
//     #47cf73
//   );
//   color: #fff;
//   font-size: 20px;
//   font-weight: bold;
//   letter-spacing: 2px;
//   word-spacing: 4px;
  text-transform: uppercase;
//   // box-shadow: rgba(60, 64, 67, 1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

//   animation: rainbow-border 25s linear infinite;
//   -webkit-text-fill-color: transparent;
//   -webkit-background-clip: text;
//   background-size: 200% 200%;

// @keyframes rainbow-border {
// 0% {
//   background-position: 0% 50%;
// }

// 50% {
//   background-position: 100% 50%;
// }

// 100% {
//   background-position: 200% 50%;
// }
//   color: #f35626;
// 	background-image: -webkit-linear-gradient(92deg, #f35626, #feab3a);
// 	-webkit-background-clip: text;
// 	-webkit-text-fill-color: transparent;
// 	-webkit-animation: hue 30s infinite linear;
// }

// @-webkit-keyframes hue {
//   from {
//     -webkit-filter: hue-rotate(0deg);
//   }
//   to {
//     -webkit-filter: hue-rotate(-360deg);
//   }
  
  }
  
  `;

  export const TextPrice = styled.p`
  font-size: 2.2rem;
  line-height: 1.6;
  font-family: "Open Sans";
  letter-spacing: .08em;
  position: relative;
  color: white;
  margin: 0px 0px 5px 0px;
  background: linear-gradient(
    70deg,
    #45275B,
    #054C66,
    #47cf73
  );
  color: #fff;
  text-transform: uppercase;
  @media only screen and (max-width: 40em) { 
    font-size: 2.4rem;
         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */   
  // box-shadow: rgba(60, 64, 67, 1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

  animation: rainbow-border 25s radial infinite;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-size: 200% 200%;

@keyframes rainbow-border {
0% {
  background-position: 0% 50%;
}

50% {
  background-position: 100% 50%;
}

100% {
  background-position: 200% 50%;
}

    // background: -webkit-linear-gradient(0deg, #ff6364, #7a9dff);
    // color: #ff6364;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  // color: #fff;
  }
  
  `;

  export const ContainerButton = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "center")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  // background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  height: 100%;
  position: relative;
  animation: none;
  // border: 2px solid white;
  grid-column: span 4 / auto;
  grid-row: span 3 / auto;
  // grid-row: span 3 /auto ;
  // border-top: 2px solid white;
  // border-bottom: 2px solid white;
  // transform: translateY(-60%);
  // margin-top: .5rem;
  // margin-bottom: .5rem;
  opacity: 100%;
  // border: 1px solid black;
  // border-radius: 200px;
  // animation:  glow 10s infinite;

  // box-shadow: rgba(60, 64, 67, 1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  @media only screen and (max-width: 40em) { 
    // margin-bottom: 5%;
    // grid-column: 1;
    // grid-row: 5;
    // grid-area: 5 / 1 / 6 / 2;
    // border-radius: 100px;

         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */   
`;



export const Header = styled.div`
// background: #eee;
border: 3px solid #black;
border-radius: 50px;
padding: 1rem;
text-align: center;
width: 30%;
position: fixed;
align-items: center;
margin-left: 100%;
// margin-bottom: 65%;
box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
//   @media only screen and (max-width: 40em) { 
// position: absolute;
// display: flex;
// justify-content: space-between;
// flex-direction: row;
// width: 100%;
// height: 25%;
// border: none;
// box-shadow: none;
// } /* max-width 640px, mobile-only styles, use when QAing mobile issues */ 

`;

export const ContainerHeader = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "center")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  background-color: none;
  width: 100%;
  opacity: 100%;
  margin-top: 7%;
  max-width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  // border: 1px solid black;
  // transform: translateX(0%);
  animation: fadeUp 1s ease 0s 1 normal;
  @keyframes fadeUp {
    from {
      opacity: 0;
      transform: translate3d(0,-10%,0);
    }
    to {
      opacity: 1;
      transform: translate3d(0,0,0);
    }
  }
  margin-bottom: 2%;
  // box-shadow: rgba(60, 64, 67, 1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  // border-radius: 200px;
  // animation:  glow 10s infinite;
  @media only screen and (max-width: 40em) { 
    width: 100%;
    transform: translateX(0%);
    border-radius: 100px;
         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */   
`;

export const ContainerMarquee = styled.div`
  grid-column: span 3 / auto;
  grid-row: span 2 / auto;
  // margin: 5% 10px 0px 3%;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "center")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  height: 100%
  // transform: translateY(35%);
  // border: 2px solid white;
  border-right: 2px solid white;
  border-top: 2px solid white;
  overflow: hidden;
  @media only screen and (max-width: 40em) { 
    // grid-column: 1;
    // grid-row: 3;
    grid: 3 / 1 / 4 / 2;
    // border-left: 2px solid white;
         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */  
  // :hover {
  // animation: wobble 1.5s 1;
  // @keyframes wobble{
  //       25% {
  //         transform: rotate(.5deg);
  //       }
  //       50% {
  //         transform: rotate(-.5deg);
  //       }
  //       75% {
  //         transform: rotate(.5deg);
  //       }
  //       100% {
  //         transform: rotate(-.5deg);
  //       }
  //     }
  //   }

  
`;

export const ContainerHeaderBox = styled.div`
  grid-column: span 3 / auto;
  // margin: 5% 10px 0px 3%;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "center")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  // background-color: white;
  width: auto;
  height: auto;
  // transform: translateY(35%);
  // border: 2px solid white;
  // border-left: 2px solid white;
  // border-top: 2px solid white;
  overflow: hidden;
  @media only screen and (max-width: 40em) { 
    // grid-column: 1;
    // grid-row: 2;
    // grid-area: 2 / 1 / 3 / 2;
    border-left: none;
         } /* max-width 640px, mobile-only styles, use when QAing mobile issues */ 
  

  
  // :hover {
  // animation: wobble 1.5s 1;
  // @keyframes wobble{
  //       25% {
  //         transform: rotate(.5deg);
  //       }
  //       50% {
  //         transform: rotate(-.5deg);
  //       }
  //       75% {
  //         transform: rotate(.5deg);
  //       }
  //       100% {
  //         transform: rotate(-.5deg);
  //       }
  //     }
  //   }


  
`;

export const TextSummaryBox = styled.div`
  grid-column: span 4 / auto;
  grid-row: span 3 / auto;
  // margin: 5% 10px 0px 3%;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "center")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  // background-color: white;
  width: 100%;
  height: 100%;
  // transform: translateY(35%);
  // border: 2px solid white;
  // border-left: 2px solid white;
  // border-top: 2px solid white;
  overflow: hidden;
  @media only screen and (max-width: 40em) { 
    // grid-column: 1;
    // grid-row: 2;
    // grid-area: 4 / 1 / 5 / 2;
    border-left: none;
    // border-top: 2px solid white;
  //   @media all and (min-width: 480px) and (max-width: 768px) { 
  //   font-size: 5rem;
  //  } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
  // :hover {
  // animation: wobble 1.5s 1;
  // @keyframes wobble{
  //       25% {
  //         transform: rotate(.5deg);
  //       }
  //       50% {
  //         transform: rotate(-.5deg);
  //       }
  //       75% {
  //         transform: rotate(.5deg);
  //       }
  //       100% {
  //         transform: rotate(-.5deg);
  //       }
  //     }
  //   }


  
`;

export const ContainerCountdown = styled.div`
  // grid-column: span 4 / auto;
// margin: -180px 10px 0px -10%;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "center")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  // transform: translate(25%, -101%);
  // border: 2px solid white;
  
  overflow: hidden;
  // :hover {
  // animation: wobble 1.5s 1;
  // @keyframes wobble{
  //       25% {
  //         transform: rotate(.5deg);
  //       }
  //       50% {
  //         transform: rotate(-.5deg);
  //       }
  //       75% {
  //         transform: rotate(.5deg);
  //       }
  //       100% {
  //         transform: rotate(-.5deg);
  //       }
  //     }
  //   }

  
`;

export const ContainerFeedback = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  // transform: translateY(-50%);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;